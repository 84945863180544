
  import { Component } from 'vue-property-decorator'
  import Cell from './cell'
  import { DateGenerator } from '@/utils/date/DateGenerator'
  import { parseToNumber } from '@/utils/general'
  import StockDocumentationForm from '@/views/forms/custom/StockDocumentationForm.vue'
  import { mapMutations } from 'vuex'

@Component({
  components: { StockDocumentationForm },
  methods: {
    ...mapMutations('resources/form', ['setBackup']),
  },
})
  export default class StockDocumentationCell extends Cell {
  setBackup!: (payload) => void
  stock = null
  components = null
  date = null
  open = false
  task = null
  withOutQualification = false

  async mounted () {
    const { item, path, isOtherFile } = this

    const tasks = (await this.fetchData({
      query: { name: 'find', model: 'EnablementTask' },
      filter: { id_enablement: { _eq: item.viewDetails.idEnablement } },
      force: true,
    }))
    const task = tasks?.find(t => t.type.name === path)
    this.task = task
    let inspectionComponent = null

    if (task && !isOtherFile) {
      inspectionComponent = await this.fetchData({
      query: { name: 'find', model: 'InspectionQualification' },
      filter: {
        component: {
          _and: [
            { id_process_record: { _eq: task.id } },
            { process: { table_name: { _eq: 'enablement_task' } } },
            {
              _or: [
                { inspection_component: { component: { category: { name: { _in: ['Documentación'] } } } } },
              ],
            },
          ],
        },
      },
      force: true,
      })

      this.components = inspectionComponent?.filter(ic => ic.component?.inspectionComponent?.component?.slug === path)
      if (!this.components?.length) {
        this.withOutQualification = true
        const enablementInspectionComponent = await this.fetchData({
        query: { name: 'find', model: 'InspectionQualification' },
        filter: {
          component: {
            _and: [
              { id_process_record: { _eq: item.viewDetails.idEnablement } },
              { process: { table_name: { _eq: 'enablement' } } },
              {
                _or: [
                  { inspection_component: { component: { category: { name: { _in: ['Documentación'] } } } } },
                ],
              },
            ],
          },
        },
        force: true,
        })
        this.components = enablementInspectionComponent?.filter(ic => ic.component?.inspectionComponent?.component?.slug === path)
        this.date = this.getInspectionViewByOrder(this.components, 2)?.value
      }

      this.date = this.getInspectionViewByOrder(this.components, 2)?.value
    } else if (isOtherFile) {
      await this.getFiles(path, item.viewDetails.idNegotiation)
    } else if (!task) {
      inspectionComponent = await this.fetchData({
      query: { name: 'find', model: 'InspectionQualification' },
      filter: {
        component: {
          _and: [
            { id_process_record: { _eq: item.viewDetails.idEnablement } },
            { process: { table_name: { _eq: 'enablement' } } },
            {
              _or: [
                { inspection_component: { component: { category: { name: { _in: ['Documentación'] } } } } },
              ],
            },
          ],
        },
      },
      force: true,
    })
      this.components = inspectionComponent?.filter(ic => ic.component?.inspectionComponent?.component?.slug === path)
      this.date = this.getInspectionViewByOrder(this.components, 2)?.value
    }
  }

  get fixDate (): string {
    if (!this.date) return ''
    const months: Record<number, string> = {
      1: 'Ene',
      2: 'Feb',
      3: 'Mar',
      4: 'Abr',
      5: 'May',
      6: 'Jun',
      7: 'Jul',
      8: 'Ago',
      9: 'Sep',
      10: 'Oct',
      11: 'Nov',
      12: 'Dic',
    }

    const [day, month, year] = this.date.split('/')
    const monthNumber = parseToNumber(month)

    return `${day}/${months[monthNumber]}/${year}`
  }

  get path () {
    const { options } = this

    return options?.path
  }

  get isOtherFile () {
    const files = ['cav', 'penalty_certificate']
    return files.includes(this.path)
  }

  get expiration () {
    const { fixDate } = this

    return DateGenerator.calculateMonthsDifferenceWithCurrentDate(fixDate) || -1
  }

  getInspectionViewByOrder (inspectionViews, order: number) {
    return inspectionViews?.find(iv => iv.parameter.order === order)
  }

  get icon () {
    const { expiration } = this

    if (expiration < 0) {
      return 'mdi-close-circle-outline'
    }

    if (expiration < 3) {
      return 'mdi-clock-outline'
    }

    return 'mdi-check-circle-outline'
  }

  get iconColor () {
    const { expiration } = this

    if (expiration < 0) {
      return 'red'
    }

    if (expiration < 3) {
      return 'orange'
    }

    return 'green'
  }

  get isEditable () {
    const { expiration, task, withOutQualification, isOtherFile } = this

    if (isOtherFile) {
      return false
    }

    if (task?.status?.isToConfirm && !withOutQualification) {
      return false
    }

    return expiration < 3
  }

  async getFiles (fileName, id) {
    const process = (await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'negotiation' } },
    }))[0]

    const fileProcess = (await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: { _and: [{ id_process_record: { _eq: id } }, { parameter: { process: { id: { _eq: process.id } } } }, { parameter: { name: { _eq: fileName } } }] },
      force: true,
    }))[0]

    this.date = fileProcess?.file?.emissionDate
  }

  async openModal () {
    const { path, task } = this

    this.setBackup({ path, task })

    await this.$router.push({
        name: 'generic-stock-documentation',
        params: { uid: this.item.id },
    }).catch(() => {})
  }
  }
