import { Component } from 'vue-property-decorator'
import { EnablementView } from './EnablementView'
import { toCamelCase } from '@/utils/general'
import dayjs from 'dayjs'

@Component
export class DocumentationView extends EnablementView {
  async handleDocument (viewData) {
    const newTask = await this.insertTask(viewData)

    await this.insertInspectionInspectedComponent(viewData, newTask)

    await this.insertPaymentOrder(viewData, newTask.id)
  }

  async insertPaymentOrder (viewData, id) {
    const {
      enablement: { stock: { viewDetails } },
      path,
      formData,
      lastOrder,
      processEnablementTaskId,
    } = viewData

    const payments = []
    const fields = {
      id_process: processEnablementTaskId,
      id_deal: viewDetails.idDeal,
      id_process_record: id,
      id_person: lastOrder.items[0].recipients[0].person.id,
      amount: formData[toCamelCase(path)].cost,
      support_number: formData.documentNumber,
    }

    const order = await this.pushData({
      model: 'PaymentOrder',
      fields,
    })
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'payment' } },
    })
    const fileInfo = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { _and: [{ process: { table_name: { _eq: 'payment_order' } } }, { name: { _eq: 'support' } }] },
    })
    const backup = {
      properties: {
        required: true,
        properties: {
          label: fileInfo[0].description,
          multiple: fileInfo[0].multiple,
          accept: fileInfo[0].fileType.mimes,
          fileTypeId: fileInfo[0].fileType.id,
          name: fileInfo[0].name,
          required: fileInfo[0].required,
        },
      },
    }

    await this.handleFileType(formData?.document, { properties: backup }, process[0].id, order.id, fileInfo)

    const expenseMap = this.calculateExpenses(lastOrder)

    const orderItems = await Promise.all([...expenseMap.entries()].map(async ([name, totalAmount]) => {
      const processExpense = await this.fetchData({
        query: { name: 'find', model: 'ProcessExpense' },
        filter: { expense: { name: { _eq: path } } },
      })

      const orderItem = await this.pushData({
        model: 'PaymentOrderItem',
        fields: {
          id_payment_order: order.id,
          amount: totalAmount,
          id_process_expense: processExpense?.[0]?.id,
        },
      })

      return {
        name,
        id: orderItem.id,
      }
    }))

    const recipientMap = new Map()

    for (const item of lastOrder.payments) {
      const orderItem = orderItems.find(oi => oi.name === item.type)
      const recipientKey = `${order.id}_${orderItem.id}_${item?.person?.id}`

      if (!recipientMap.has(recipientKey)) {
        const recipient = await this.pushData({
          model: 'PaymentRecipient',
          fields: {
            id_payment_order: order.id,
            id_payment_order_item: orderItem.id,
            id_person: item?.person?.id,
          },
        })
        recipientMap.set(recipientKey, recipient.id)
      }
    }

    await Promise.all(lastOrder.payments.map(async item => {
      const orderItem = orderItems.find(oi => oi.name === item.type)
      const recipientKey = `${order.id}_${orderItem.id}_${item?.person?.id}`
      const recipientId = recipientMap.get(recipientKey)

      const pay = await this.insertPaymentToOrder(viewData, { id: recipientId }, item)

      payments.push(pay)
    }))

    return payments
  }

  async insertPaymentToOrder (viewData, recipient, item) {
    const { formData: { date, document }, enablement: { stock: { viewDetails } }, path, formData } = viewData
    const pending = (await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'payment' } } }, { status: { name: { _eq: 'pending' } } }] },
    }))[0]

    const cards = ['credit_card', 'debit_card']
    const fields = {
      id_financial_account: cards?.includes(item?.paymentType?.name) ? item?.account?.id : null,
      id_process_status: pending.id,
      id_payment_type: item?.paymentType?.id,
      id_payment_recipient: recipient.id,
      amount: item?.amount,
      id_deal: viewDetails.idDeal,
      date: dayjs(date, 'DD/MM/YYYY HH:mm'),
      comment: item?.paymentRecipient?.payments[0]?.comment,
      id_recipient_account: null,
    }

    const newPayment = await this.pushData({
      model: 'Payment',
      fields,
    })

    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'payment' } },
    })
    const fileInfo = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { _and: [{ process: { table_name: { _eq: 'payment' } } }, { name: { _eq: 'spending_support' } }] },
    })

    const fileInfoExpense = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { _and: [{ process: { table_name: { _eq: 'payment' } } }, { name: { _eq: 'expense_payment' } }] },
    })
    const backup = {
      properties: {
        required: true,
        properties: {
          label: fileInfo[0].description,
          multiple: fileInfo[0].multiple,
          accept: fileInfo[0].fileType.mimes,
          fileTypeId: fileInfo[0].fileType.id,
          name: fileInfo[0].name,
          required: fileInfo[0].required,
        },
      },
    }

    await this.handleFileType(formData[toCamelCase(path)].photo, { properties: backup }, process[0].id, newPayment.id, fileInfo)

    const backupExpense = {
      properties: {
        required: true,
        properties: {
          label: fileInfoExpense[0].description,
          multiple: fileInfoExpense[0].multiple,
          accept: fileInfoExpense[0].fileType.mimes,
          fileTypeId: fileInfoExpense[0].fileType.id,
          name: fileInfoExpense[0].name,
          required: fileInfoExpense[0].required,
        },
      },
    }

    await this.handleFileType(document, { properties: backupExpense }, process[0].id, newPayment.id, fileInfoExpense)

    return newPayment
  }

  calculateExpenses (expenses): Map<string, number> {
    const expenseMap = new Map()

    if (!expenses?.payments?.length) return expenseMap

    const payments = expenses?.payments?.filter(payment => !payment?.id)

    payments.forEach(item => {
      const itemName = item.type
      const amount = parseInt(item?.amount) || 0

      if (expenseMap.has(itemName)) {
        expenseMap.set(itemName, expenseMap.get(itemName) + amount)
      } else {
        expenseMap.set(itemName, amount)
      }
    })

    return expenseMap
  }

  async insertInspectionInspectedComponent (viewData, newTask) {
    const { path, formData, components, fields } = viewData
    const process = (await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'inspection_inspected_component' } },
    }))[0]

    const component = components?.find(ic => ic.component.slug === path)

    const slug = toCamelCase(path)

    const processEnablementTask = (await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'enablement_task' } },
    }))[0]
    const path2 = ['soap', 'technical_review']
    if (path2.includes(path)) {
      await this.insertComponent(component, newTask, formData[slug], fields, slug, process, viewData, processEnablementTask)
    } else {
      await this.insertComponentCirculation(component, newTask, formData[slug], fields, slug, process, viewData, processEnablementTask)
    }
  }

  async insertTask (viewData) {
    const { enablement, components, path, formData } = viewData

    const inspectionComponent = components?.find(ic => ic.component.slug === path)
    const process = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'enablement_task' } } }] },
    })

    const status = process.find(p => p.name === 'to_confirm')

    const taskTypes = await this.fetchData({
      query: { name: 'find', model: 'EnablementTaskType' },
      filter: {},
      force: true,
    })

    const taskType = taskTypes.find(tt => tt.name === path)

    return this.pushData({
      model: 'EnablementTask',
      fields: {
        // id: enablement.id,
        id_enablement: enablement.id,
        cost: formData[toCamelCase(path)]?.cost,
        id_employee: this.idEmployee,
        id_process_status: status.id,
        id_enablement_task_type: taskType.id,
      },
    })
  }

  getInspectionViewByOrder (inspectionViews, order) {
    return inspectionViews?.find(iv => iv.parameter.order === order)
  }
}
