import { Component } from 'vue-property-decorator'
import { InspectionBase } from '@/components/forms/inspection/InspectionBase'
import { DateFormatter } from '@/utils/date/DateFormatter'
import { InspectionQualification } from '@/entities/purchase'
import { isValidNumber } from '@/utils/general'

@Component
export class EnablementView extends InspectionBase {
  enablementIdProcess = null
  enablementClosingReason = null
  stockStatus = null
  enablementStatus = {
    active: null,
    closed: null,
    toConfirm: null,
    toUpdate: null,
  }

  async mounted () {
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'enablement' } },
    })

    if (process?.length) {
      this.enablementIdProcess = process[0].id
    }

    this.enablementStatus.active = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'enablement' } } }, { status: { name: { _eq: 'active' } } }] },
    })

    this.enablementStatus.toConfirm = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'enablement' } } }, { status: { name: { _eq: 'to_confirm' } } }] },
    })

    this.enablementStatus.toUpdate = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'enablement' } } }, { status: { name: { _eq: 'to_update' } } }] },
    })

    this.enablementStatus.closed = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'enablement' } } }, { status: { name: { _eq: 'closed' } } }] },
    })

    this.enablementClosingReason = await this.fetchData({
      query: { name: 'find', model: 'ClosingReason' },
      filter: {
        _and: [
          { type: { name: { _eq: 'successful' } } },
          { status: { process: { table_name: { _eq: 'enablement' } } } },
        ],
      },
    })

    this.stockStatus = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'stock' } } }, { status: { name: { _eq: 'available' } } }] },
    })
  }

  handleEnablement (viewData) {
    const {
      formData: { bodyWork, inside, wheels, mechanical, clean, answerDocuments, key, answerDocumentsCost },
      enablement,
    } = viewData

    const keys = ['bodyWork', 'wheels', 'inside', 'mechanical', 'clean']

    enablement.metadata.inspector.documentation.value = answerDocuments
    if (enablement?.metadata?.supervisor?.documentation) {
      enablement.metadata.supervisor.documentation.comment = ''
    }

    keys.forEach(key => {
      enablement.metadata.inspector[key].value = viewData?.formData[key].answer
      enablement.metadata.inspector[key].cost = viewData.formData[key].cost
      if (enablement?.metadata?.supervisor?.[key]) {
        enablement.metadata.supervisor[key].comment = ''
      }
    })

    enablement.metadata.inspectorCost = (Number(clean.cost) || 0) + (Number(mechanical.cost) || 0) +
      (Number(wheels.cost) || 0) + (Number(inside.cost) || 0) + (Number(bodyWork.cost) || 0) +
      (Number(answerDocumentsCost) || 0) + (Number(key.cost) || 0)
  }

  async handleEnablementExecutive (viewData, formData) {
    const {
      formData: { bodyWork, inside, wheels, mechanical, clean, answerDocuments, answerDocumentsCost, mileage, key },
      enablement,
    } = viewData

    await this.handleDocuments(viewData, formData)

    let newEnablement
    if (enablement.metadata) {
      this.handleEnablement(viewData)

      newEnablement = await this.pushData({
        model: 'Enablement',
        fields: {
          id: enablement.id,
          metadata: enablement.metadata,
        },
      })
    } else {
      const metadata = {
        inspector: {
          documentation: {
            value: answerDocuments,
            cost: answerDocumentsCost,
            comment: '',
          },
          bodyWork: {
            value: bodyWork.answer,
            cost: bodyWork.cost,
            comment: '',
          },
          wheels: {
            value: wheels.answer,
            cost: wheels.cost,
            comment: '',
          },
          inside: {
            value: inside.answer,
            cost: inside.cost,
            comment: '',
          },
          mechanical: {
            value: mechanical.answer,
            cost: mechanical.cost,
            comment: '',
          },
          clean: {
            value: clean.answer,
            cost: clean.cost,
            comment: '',
          },
        },
        inspectorCost: (Number(clean.cost) || 0) + (Number(mechanical.cost) || 0) +
          (Number(wheels.cost) || 0) + (Number(inside.cost) || 0) + (Number(bodyWork.cost) || 0) +
          (Number(answerDocumentsCost) || 0) + (Number(key.cost) || 0),
      }

      newEnablement = await this.pushData({
        model: 'Enablement',
        fields: {
          id: enablement.id,
          metadata,
          id_enabler: this.idEmployee,
        },
      })
    }

    await this.handleEnablementFiles(newEnablement, viewData)

    await this.dealAutoAttribute(enablement, mileage)

    if (newEnablement.metadata.inspectorDone) {
      await this.pushData({
        model: 'Enablement',
        fields: {
          id: enablement.id,
          id_process_status: this.enablementStatus.toConfirm[0].id,
        },
      })
    } else {
      await this.pushData({
        model: 'Enablement',
        fields: {
          id: enablement.id,
          id_process_status: this.enablementStatus.active[0].id,
        },
      })
    }
  }

  async handleEnablementFiles (enablement, viewData) {
    const { formData, processEnablementId } = viewData

    const keys = ['bodyWork', 'wheels', 'inside', 'mechanical']

    for (const key of keys) {
      if (formData[key].photo.length) {
        await this.handleFileType(formData[key].photo, { properties: viewData.fields[key].properties }, processEnablementId, enablement.id)
      }
    }

    const cleanKeys = ['photoFront', 'photoRear', 'photoChassis', 'photoEngine']
    const cleanProperties = {
      photoFront: 'frontPilot',
      photoRear: 'rearPilot',
      photoChassis: 'chassis',
      photoEngine: 'engine',
    }

    for (const key of cleanKeys) {
      if (formData.clean[key].length) {
        await this.handleFileType(
          formData.clean[key],
          { properties: viewData.fields[cleanProperties[key]].properties },
          processEnablementId,
          enablement.id
        )
      }
    }
  }

  async handleDocuments (viewData, formData) {
    const {
      enablement,
      components,
      fields,
    } = viewData

    const { soap, technicalReview, circulationPermit, mileagePhoto, mileage, key } = formData

    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'inspection_inspected_component' } },
    })
    await Promise.all([
      await this.insertComponent(components?.find(ic => ic.component.slug === 'technical_review'), enablement, technicalReview, fields, 'technicalReview', process, viewData),
      await this.insertComponent(components?.find(ic => ic.component.slug === 'soap'), enablement, soap, fields, 'soap', process, viewData),
    ])

    await this.insertKey(components.filter(c => c.component.slug === 'basic_equipment')[0], enablement, key, fields, 'key', process, viewData)
    await this.insertComponentMileage(components?.find(ic => ic.component.slug === 'mileage'), enablement, {
      photo: mileagePhoto.photo,
      mileage,
    }, fields, 'mileageComponent', process, viewData)

    await this.insertComponentCirculation(components?.find(ic => ic.component.slug === 'circulation_permit'), enablement, circulationPermit, fields, 'circulationPermit', process, viewData)
  }

  async insertKey (component, enablement, data, fieldsData, slug, process, viewData) {
    const { idEmployee, enablementIdProcess } = this
    const exist = viewData[slug].some(v => v instanceof InspectionQualification)
    let resp

    const copy = data.copy.answer

    if (exist) {
      const inspectionInspectedComponent = await this.fetchData({
        query: { name: 'find', model: 'InspectionInspectedComponent' },
        filter: { inspection_qualifications: { id: { _eq: viewData[slug][0].id } } },
      })

      resp = inspectionInspectedComponent[0]

      await this.pushData({
        model: 'InspectionQualification',
        fields: {
          id: this.getInspectionViewByOrder(viewData[slug], 2)?.id,
          id_inspection_assessment: copy,
        },
      })

      await this.pushData({
        model: 'InspectionInspectedComponent',
        fields: { id: resp.id, cost: isValidNumber(data.cost) ? data.cost : 0 },
      })
    } else {
      const fields = {
        id_process_record: enablement.id,
        id_process: enablementIdProcess,
        id_inspection_component: component.id,
        cost: isValidNumber(data.cost) ? data.cost : 0,
        comment: undefined,
        inspection_qualifications: {
          data: [
            {
              id_employee: idEmployee,
              id_inspection_parameter: component?.findInspectionParameterByOrder(2)?.id,
              id_inspection_assessment: copy,
            },
          ],
        },
      }

      resp = await this.pushData({ model: 'InspectionInspectedComponent', fields })
    }

    if (resp) {
      await this.handleFileType(data.photo, { properties: fieldsData[slug].properties }, process.id, resp.id)
    }
  }

  async insertComponentMileage (component, enablement, data, fieldsData, slug, process, viewData) {
    const { idEmployee, enablementIdProcess } = this
    const exist = viewData[slug].some(v => v instanceof InspectionQualification)
    let resp

    if (exist) {
      const inspectionInspectedComponent = await this.fetchData({
        query: { name: 'find', model: 'InspectionInspectedComponent' },
        filter: { inspection_qualifications: { id: { _eq: viewData[slug][0].id } } },
      })

      resp = inspectionInspectedComponent[0]

      await this.pushData({
        model: 'InspectionQualification',
        fields: {
          id: viewData[slug][0]?.id,
          value: data?.mileage,
        },
      })

      await this.pushData({
        model: 'InspectionInspectedComponent',
        fields: { id: resp.id, cost: isValidNumber(data.cost) ? data.cost : 0 },
      })
    } else {
      const fields = {
        id_process_record: enablement.id,
        id_process: enablementIdProcess,
        id_inspection_component: component.id,
        cost: isValidNumber(data.cost) ? data.cost : 0,
        comment: undefined,
        inspection_qualifications: {
          data: [
            {
              id_employee: idEmployee,
              id_inspection_parameter: component?.inspectionParameters[0]?.id,
              value: data?.mileage,
            },
          ],
        },
      }

      resp = await this.pushData({ model: 'InspectionInspectedComponent', fields })
    }

    if (resp) {
      await this.handleFileType(data.photo, { properties: fieldsData.mileagePhoto.properties }, process.id, resp.id)
    }
  }

  async insertComponentCirculation (component, enablement, data, fieldsData, slug, process, viewData, otherProcess = null) {
    const { idEmployee, enablementIdProcess } = this

    const exist = !otherProcess ? viewData[slug].some(v => v instanceof InspectionQualification) : null
    let resp
    if (exist) {
      const inspectionInspectedComponent = await this.fetchData({
        query: { name: 'find', model: 'InspectionInspectedComponent' },
        filter: { inspection_qualifications: { id: { _eq: viewData[slug][0].id } } },
      })

      resp = inspectionInspectedComponent[0]

      await this.pushData({
        model: 'InspectionQualification',
        fields: {
          id: this.getInspectionViewByOrder(viewData[slug], 1)?.id,
          value: data?.selection.name,
        },
      })

      await this.pushData({
        model: 'InspectionQualification',
        fields: {
          id: this.getInspectionViewByOrder(viewData[slug], 2)?.id,
          value: DateFormatter.toValidDBDate(data?.date),
        },
      })

      await this.pushData({
        model: 'InspectionQualification',
        fields: {
          id: this.getInspectionViewByOrder(viewData[slug], 3)?.id,
          value: data?.amount.toString() || '0',
        },
      })

      await this.pushData({
        model: 'InspectionInspectedComponent',
        fields: { id: resp.id, cost: isValidNumber(data.cost) ? data.cost : 0 },
      })
    } else {
      const fields = {
        id_process_record: enablement.id,
        id_process: otherProcess?.id || enablementIdProcess,
        id_inspection_component: component.id,
        cost: data?.cost || 0,
        comment: undefined,
        inspection_qualifications: {
          data: [
            {
              id_employee: idEmployee,
              id_inspection_parameter: component?.findInspectionParameterByOrder(1)?.id,
              value: data?.selection.name,
            },
            {
              id_employee: idEmployee,
              id_inspection_parameter: component?.findInspectionParameterByOrder(2)?.id,
              value: DateFormatter.toValidDBDate(data?.date),
            },
            {
              id_employee: idEmployee,
              id_inspection_parameter: component?.findInspectionParameterByOrder(3)?.id,
              value: data?.amount?.toString() || '0',
            },
          ],
        },
      }

      resp = await this.pushData({ model: 'InspectionInspectedComponent', fields })
    }
    if (resp) {
      await this.handleFileType(data.photo, { properties: fieldsData[slug].properties }, process.id, resp.id)
    }
  }

  async insertComponent (component, enablement, data, fieldsData, slug, process, viewData, otherProcess = null) {
    const { idEmployee, enablementIdProcess } = this

    const exist = !otherProcess ? viewData[slug]?.some(v => v instanceof InspectionQualification) : null

    let resp
    if (exist) {
      const inspectionInspectedComponent = await this.fetchData({
        query: { name: 'find', model: 'InspectionInspectedComponent' },
        filter: { inspection_qualifications: { id: { _eq: viewData[slug][0].id } } },
      })

      resp = inspectionInspectedComponent[0]

      await this.pushData({
        model: 'InspectionQualification',
        fields: {
          id: this.getInspectionViewByOrder(viewData[slug], 1)?.id,
          value: data.selection.alias,
        },
      })

      await this.pushData({
        model: 'InspectionQualification',
        fields: {
          id: this.getInspectionViewByOrder(viewData[slug], 2)?.id,
          value: DateFormatter.toValidDBDate(data?.date),
        },
      })
      await this.pushData({
        model: 'InspectionInspectedComponent',
        fields: { id: resp.id, cost: isValidNumber(data.cost) ? data.cost : 0 },
      })
    } else {
      const fields = {
        id_process_record: enablement.id,
        id_process: otherProcess?.id || enablementIdProcess,
        id_inspection_component: component.id,
        cost: isValidNumber(data.cost) ? data.cost : 0,
        comment: undefined,
        inspection_qualifications: {
          data: [
            {
              id_employee: idEmployee,
              id_inspection_parameter: component?.findInspectionParameterByOrder(1)?.id,
              value: data.selection.alias,
            },
            {
              id_employee: idEmployee,
              id_inspection_parameter: component?.findInspectionParameterByOrder(2)?.id,
              value: DateFormatter.toValidDBDate(data?.date),
            },
          ],
        },
      }

      resp = await this.pushData({ model: 'InspectionInspectedComponent', fields })
    }
    if (resp) {
      await this.handleFileType(data.photo, { properties: fieldsData[slug].properties }, process.id, resp.id)
    }
  }

  async handleEnablementSupervisor (viewData) {
    const {
      formData: {
        bodyWork,
        bodyWorkCost,
        bodyWorkComment,
        inside,
        insideCost,
        insideComment,
        wheels,
        wheelsCost,
        wheelsComment,
        mechanical,
        mechanicalCost,
        mechanicalComment,
        clean,
        cleanCost,
        cleanComment,
        answerDocuments,
        answerDocumentsCost,
        answerDocumentsComment,
        key: { cost: keyCost, validated: keyValidation, comment: keyComment },
        mileage: { validated: mileageAnswer, comment: mileageComment },
      },
      stock,
      enablement,
    } = viewData

    await this.handleSupervisorAnswer(viewData)

    const totalCost = (Number(bodyWorkCost) || 0) + (Number(wheelsCost) || 0) +
      (Number(insideCost) || 0) + (Number(mechanicalCost) || 0) + (Number(cleanCost) || 0) +
      (Number(answerDocumentsCost) || 0) + (Number(keyCost) || 0)

    enablement.metadata = {
      ...enablement.metadata,
      supervisor: {
        documentation: {
          value: answerDocuments,
          cost: answerDocumentsCost,
          comment: answerDocumentsComment,
        },
        bodyWork: {
          value: bodyWork,
          cost: bodyWorkCost,
          comment: bodyWorkComment,
        },
        wheels: {
          value: wheels,
          cost: wheelsCost,
          comment: wheelsComment,
        },
        inside: {
          value: inside,
          cost: insideCost,
          comment: insideComment,
        },
        mechanical: {
          value: mechanical,
          cost: mechanicalCost,
          comment: mechanicalComment,
        },
        clean: {
          value: clean,
          cost: cleanCost,
          comment: cleanComment,
        },
        key: {
          cost: keyCost,
          value: keyValidation,
          comment: keyComment,
        },
        mileage: {
          cost: null,
          value: mileageAnswer,
          comment: mileageComment,
        },
      },
      totalCost,
    }

    const newEnablement = await this.pushData({
      model: 'Enablement',
      fields: {
        id: enablement.id,
        metadata: enablement.metadata,
        id_supervisor: this.idEmployee,
      },
    })

    if (newEnablement.metadata.isSupervisorDone) {
      await this.pushData({
        model: 'Enablement',
        fields: {
          id: enablement.id,
          id_process_status: this.enablementStatus.closed[0].id,
          id_closing_reason: this.enablementClosingReason[0].id,
          id_supervisor: this.idEmployee,
        },
      })
      if (stock.status.isEnabling) {
        await this.pushData({
          model: 'Stock',
          fields: {
            id: stock.id,
            id_process_status: this.stockStatus[0].id,
          },
        })
      }
    } else {
      await this.pushData({
        model: 'Enablement',
        fields: {
          id: enablement.id,
          id_process_status: this.enablementStatus.toUpdate[0].id,
          id_supervisor: this.idEmployee,
        },
      })
    }
  }

  async handleSupervisorAnswer (viewData) {
    const {
      formData: {
        soap: { photo: soapPhoto },
        soapComment,
        soapAnswer,
        technicalReviewAnswer,
        circulationPermitAnswer,
        technicalReview: { photo: technicalPhoto },
        technicalReviewComment,
        circulationPermit: { photo: circulationPermitPhoto },
        circulationPermitComment,
        key: { comment: keyComment, validated: keyAnswer, photo: keyPhoto, supervisorCost: keyCost },
        mileage: { photo: mileagePhoto, validated: mileageAnswer, comment: mileageComment },
      },
      soap,
      technicalReview,
      circulationPermit,
      key,
      mileageComponent,
    } = viewData

    await this.insertComponentAnswer(mileageAnswer, mileageComponent, mileagePhoto, mileageComment)
    await this.insertComponentAnswer(keyAnswer, key, keyPhoto, keyComment, keyCost)
    await this.insertComponentAnswer(soapAnswer, soap, soapPhoto, soapComment)
    await this.insertComponentAnswer(technicalReviewAnswer, technicalReview, technicalPhoto, technicalReviewComment)
    await this.insertComponentAnswer(circulationPermitAnswer, circulationPermit, circulationPermitPhoto, circulationPermitComment)
  }

  async insertComponentAnswer (answer, qualifications: InspectionQualification[], photo, comment, cost = null) {
    if (cost) {
      const InspectionInspectedComponent = await this.fetchData({
        query: { name: 'find', model: 'InspectionInspectedComponent' },
        filter: { inspection_qualifications: { id: { _eq: qualifications[0].id } } },
      })

      await this.pushData({
        model: 'InspectionInspectedComponent',
        fields: { id: InspectionInspectedComponent[0].id, supervisor_cost: cost },
      })
    }

    await Promise.all(qualifications.map(async qualification => {
      const fields = {
        id: qualification.id,
        validated: answer,
      }

      await this.pushData({ model: 'InspectionQualification', fields })
    }))

    if (photo?.length) {
      await this.insertFileProcessInfo({
        id: photo[0].id,
        comment: !answer ? comment : '',
        valid: answer,
        validation_type: 'manual',
      })
    }
  }

  async dealAutoAttribute (enablement, value) {
    const { enablementIdProcess } = this

    const mileageComponent = await this.fetchData({
      query: { name: 'find', model: 'Component' },
      filter: { slug: { _eq: 'mileage' } },
    })

    const mileage = await this.getDealAutoAttribute(mileageComponent[0].id, enablementIdProcess, enablement.id)
    let fields

    if (mileage.length) {
      fields = {
        id: mileage[0].id,
        value,
      }
    } else {
      fields = {
        id_process_record: enablement.id,
        value,
        id_process: enablementIdProcess,
        id_component: mileageComponent[0].id,
        id_deal: enablement.stock.viewDetails.idDeal,
      }
    }

    await this.pushData({ model: 'DealAutoAttribute', fields })
  }

  getInspectionViewByOrder (inspectionViews, order: number) {
    return inspectionViews?.find(iv => iv.parameter.order === order)
  }

  getInspectionQualificationValue (component, parameterOrder) {
    return component?.findInspectionParameterByOrder(parameterOrder)?.inspectionQualifications?.[0]?.value
  }
}
